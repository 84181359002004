<template>
  <div class="hochwacht-footer">
    <div class="container hochwacht-footer__list">
      <div class="hochwacht-footer__item content has-text-centered" id="footer-imprint">
        <nuxt-link to="/impressum"><b>Impressum</b></nuxt-link>
      </div>
      <div class="hochwacht-footer__item content has-text-centered" id="footer-info">
        <span style="font-size: 12px">
          <span><b>Copyright &copy; {{ year }} </b></span>
          <b><a target="_blank" href="https://hochwacht.ch" rel="noopener"> hochwacht.ch </a></b>
          <span>All rights reserved.</span>
        </span>
        <span>V{{ config.public.version }} <span v-if="config.public.isTestMode"
            class="hochwacht-footer__page-meta--test">TEST MODE</span> </span>
      </div>
      <div class="hochwacht-footer__item content has-text-centered" id="footer-privacy">
        <nuxt-link to="/privacypolicy"><b>Datenschutzbestimmungen</b></nuxt-link>
      </div>
    </div>
    <div class="hochwacht-footer__page-meta">
    </div>
  </div>
</template>

<script setup lang="ts">
const year: number = new Date().getFullYear();
const config = useRuntimeConfig();
</script>

<style scoped lang="scss">
.hochwacht-footer {
  border-top: 2px solid $primary;
  background-color: white;
  min-height: 50px;

  &__list {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 10px;
  }

  &__item {
    flex: 1;
    margin-bottom: 1rem;
  }

  #footer-info {
    display: flex;
    flex-direction: column;
  }

  &__page-meta {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    color: black;

    &--test {
      color: red;
    }
  }

  @media screen and (max-width: 500px) {
    #footer-info {
      order: 0;
    }

    #footer-imprint,
    #footer-privacy {
      order: 1;

    }

    &__list {
      flex-direction: column;
      justify-content: flex-start;
    }

    &__item {
      margin-bottom: 5px;
    }

  }
}
</style>
