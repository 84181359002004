<template>
  <nav class="navbar is-fixed-top" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <nuxt-link class="navbar-item" to="/">
          <img src="/img/logo.png" alt="Logo Pfadi Hochwacht Baden" />
        </nuxt-link>
        <span class="navbar-burger burger" :class="{ 'is-active': isActive }" @click.prevent="toggleNav()"
          data-target="navbarMenu" style="margin-left: auto;">
          <span></span>
          <span></span>
          <span></span>
        </span>
        <o-button id="mobileShopBtn" icon-pack="fas" label="Shop" icon-right="arrow-right" variant="primary" tag="a"
          href="https://shop.hochwacht.ch"></o-button>
      </div>

      <div class="navbar-menu" :class="{ 'is-active': isActive }">
        <div class="navbar-end" @click.prevent="hideMenu">
          <div class="navbar-item has-dropdown is-hoverable">
            <span class="navbar-link"> Abteilung </span>
            <div class="navbar-dropdown">
              <nuxt-link class="navbar-item" to="/about">Über uns</nuxt-link>
              <hr class="navbar-divider" />
              <nuxt-link class="navbar-item" to="/equipment">Ausrüstung</nuxt-link>
              <nuxt-link class="navbar-item" to="/rental">Heimvermietung</nuxt-link>
              <nuxt-link class="navbar-item" to="/contact">Kontakt</nuxt-link>
              <nuxt-link class="navbar-item" to="/documents">Unterlagen</nuxt-link>
              <nuxt-link class="navbar-item" to="/faq">FAQ</nuxt-link>
              <hr class="navbar-divider" />
              <nuxt-link class="navbar-item" to="/donation">Spenden</nuxt-link>
            </div>
          </div>
          <div class="navbar-item has-dropdown is-hoverable">
            <span class="navbar-link"> Stufen </span>
            <div v-if="!loading" class="navbar-dropdown navbar-dropdown-loading">
              <div v-for="agegroup in agegroups" v-bind:key="agegroup.id">
                <nuxt-link class="navbar-item" :to="`/agegroups/${agegroup.id}`">{{ agegroup.title }}</nuxt-link>
              </div>
            </div>
            <div v-if="loading" class="navbar-dropdown navbar__agegroups__loading">
              <o-loading v-model:active="loading" :full-page="false" icon-size="small"></o-loading>
            </div>
          </div>
          <nuxt-link class="navbar-item" to="/galleries/">Galerie</nuxt-link>
        </div>
      </div>
      <o-button id="shopBtn" label="Shop" icon-right="arrow-right" variant="primary" tag="a"
        href="https://shop.hochwacht.ch"></o-button>
    </div>
  </nav>
</template>

<script setup lang="ts">
import type { Agegroup } from '~/ts/interfaces/Agegroup';

const isActive = ref(false);
const agegroups = ref<Agegroup[]>([]);
const loading = ref(true);

onMounted(async () => {
  loading.value = true;
  const { ApiUtil } = await import('~/util/ApiUtil')
  agegroups.value = (await ApiUtil.getAgeGroups()).data
  loading.value = false;
})

function toggleNav() {
  isActive.value = !isActive.value;
}

function hideMenu() {
  setTimeout(() => {
    isActive.value = false;
  }, 100);
}
</script>

<style scoped lang="scss">
.navbar {
  background-color: #fff !important;
  box-shadow: 0px 0px 7px -4px rgba(0, 0, 0, 0.75) !important;
  border-top: 4px solid $primary;

  &__agegroups {
    &__loading {
      padding: 1rem 0;

      >* {
        color: $primary;
      }

      .icon {
        width: 25px !important;
      }
    }
  }
}

.navbar-brand {
  display: flex;
}

#shopBtn {
  margin-left: 2rem;
  align-self: center;
}

#mobileShopBtn {
  margin: 0 1.5rem 0 0.25rem;
  align-self: center;
}

@media (max-width: 1024px) {
  #shopBtn {
    display: none;
  }
}

@media (min-width: 1024px) {
  #mobileShopBtn {
    display: none;
  }
}

@media (max-width: 480px) {
  .navbar-brand img {
    height: 1.5rem;
  }

  #mobileShopBtn {
    margin: 0;
    margin-right: 0.2rem;
  }
}
</style>

<style lang="scss">
#shopBtn>span {
  display: flex;
  align-items: center;
}

.navbar__agegroups__loading {
  .loading {
    .icon {
      width: 25px !important;
    }
  }
}
</style>