<template>
  <div class="main">
    <Navbar />
    <div class="content-view">
      <slot />
    </div>
    <Footer class="hwb-footer" />
  </div>
</template>

<style lang="scss">
.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.main>.hwb-footer {
  margin-top: auto;
}

.content-view {
  margin-top: 56px;
}
</style>
